import { Component, Input, OnInit, Output, EventEmitter, ViewChild, AfterViewInit, OnChanges, SimpleChange, SimpleChanges } from '@angular/core';
import * as _ from 'lodash';
import { RoutePath } from '@entities/route-path';
import { environment } from '@environment';
import { GoogleMapsLoaderService } from '@services/maps/googleMapsLoader.service';
import { GoogleMap, MapInfoWindow, MapMarker } from '@angular/google-maps';
import { Apm } from 'projects/inpost/src/app/operations/entities/apm';

@Component({
    selector: 'gmap',
    styles: [
        `
            #map {
                width: 100%;
                height: 100%;
            }
        `,
    ],
    template: `
        <google-map #mapComponent *ngIf="isMapLoaded" height="100%" width="100%" [center]="center" [zoom]="zoom" (idle)="onMapIdle()" (mapClick)="onMapClick($event)">
            <!-- Add markers with custom-styled labels and click event -->
            <map-marker *ngFor="let point of points" [position]="point.position" [icon]="point.icon && point.icon.url ? markerPath + '/' + point.icon.url : undefined" [label]="point.label || ''" (mapClick)="openInfoWindow(point)"></map-marker>

            <!-- Info window for marker tooltips -->
            <map-info-window #infoWindow>
                <div [innerHTML]="selectedPoint?.info"></div>
                <button class="btn btn-default" (click)="removePointFromMap()">{{ 'Remove' | translate }}</button>
            </map-info-window>

            <map-marker *ngIf="options.type === 'SIMPLE'" #marker [position]="positions[0]" [options]="markerOptions" (mapDragend)="onMarkerDragEnd($event)"></map-marker>

            <!-- Add polyline to connect points with a line -->
            <map-polyline *ngIf="options.polyline" [path]="positions" [options]="polylineOptions"></map-polyline>
        </google-map>
        <div *ngIf="!isMapLoaded">Loading map...</div>
    `,
})
export class GMapComponent implements OnInit, OnChanges, AfterViewInit {
    @ViewChild('mapComponent') mapComponent!: GoogleMap; // Reference to the GoogleMap component
    @ViewChild(MapInfoWindow) infoWindow!: MapInfoWindow; // Reference to the MapInfoWindow component
    @ViewChild(MapMarker) marker!: MapMarker;

    @Input() path?: RoutePath | Apm[];
    @Input() coordinates?: google.maps.LatLngLiteral;
    @Input() options?: { polyline: boolean; removable?: boolean; clickable?: boolean; type?: string } = {
        polyline: true,
        clickable: false,
        removable: false,
        type: 'MULTI',
    };

    @Output() public markerChanged: EventEmitter<google.maps.LatLngLiteral> = new EventEmitter();
    @Output() public newMarkerOnClick: EventEmitter<google.maps.LatLngLiteral> = new EventEmitter();
    @Output() public removeMarkerOnClick: EventEmitter<string> = new EventEmitter();

    markerOptions: google.maps.MarkerOptions = {
        draggable: true,
    };

    isMapLoaded = false;
    isFirstLoad = true;
    center: google.maps.LatLngLiteral;
    zoom = 12;
    infoContent = '';
    positions: google.maps.LatLngLiteral[];
    // // Lista 10 punktów z etykietami (przykładowe lokalizacje)
    points: { position: google.maps.LatLngLiteral; label?: google.maps.MarkerLabel; info?: string }[];
    selectedPoint: { position: google.maps.LatLngLiteral; label: { text: string } };

    polylineOptions: google.maps.PolylineOptions = {
        strokeColor: '#000',
        strokeOpacity: 0.7,
        strokeWeight: 1,
    };

    infoWindowOptions = {
        color: 'black',
        fontWeight: 'bold',
        fontSize: '13px',
    };

    assetsPath = 'assets/img';
    markerPath = `${this.assetsPath}/markers`;

    constructor(private mapsLoader: GoogleMapsLoaderService) {}

    ngOnInit(): void {}

    async ngAfterViewInit() {
        const apiKey = environment.googleMapApiKey;

        await this.initMap();

        await this.mapsLoader
            .loadApi(apiKey)
            .then(() => {
                this.fitMapToMarkers();
                this.isMapLoaded = true; // Ustaw flagę, aby wyświetlić mapę
            })
            .catch((error) => {
                console.error('Błąd ładowania Google Maps:', error);
            });

        // Ensure `infoWindow` is initialized in `AfterViewInit` lifecycle hook
        if (!this.infoWindow) {
            console.warn('InfoWindow not initialized');
        }
    }

    async initMap() {
        console.log('initMap', this.path);

        switch (this.options.type) {
            case 'MULTI':
                if (!Array.isArray(this.path)) {
                    console.log('tutaj');
                    this.points = this.path.points.map((point, index) => {
                        return { position: { lat: point.lat, lng: point.lng }, label: { text: index.toString(), ...this.infoWindowOptions }, info: point?.description, icon: { url: point?.icon } };
                    });
                } else {
                    this.points = this.path.map((point, index) => {
                        return { position: { lat: point.coordinates.lat, lng: point.coordinates.lng }, label: { text: index.toString(), ...this.infoWindowOptions }, info: point.name };
                    });
                }
                break;
            case 'SIMPLE':
                this.points = [{ position: { lat: this.coordinates.lat, lng: this.coordinates.lng }, label: { text: '1', ...this.infoWindowOptions } }];
                break;
        }

        this.center = this.points[0]?.position;
        console.log(this.points);

        this.positions = this.points.filter((p) => p.label).map((point) => point.position);
    }

    ngOnChanges(changes: SimpleChanges) {
        this.initMap();

        console.log('zmiana', changes);
    }

    onMarkerDragEnd(event: google.maps.MapMouseEvent) {
        if (event.latLng) {
            this.positions[0] = {
                lat: event.latLng.lat(),
                lng: event.latLng.lng(),
            };
            this.markerChanged.emit(this.positions[0]);
        }
    }

    onMapClick(event: google.maps.MapMouseEvent) {
        if (event.latLng && this.options.clickable) {
            const coords = {
                lat: event.latLng.lat(),
                lng: event.latLng.lng(),
            };

            this.points.push({ position: coords });
            this.newMarkerOnClick.emit(coords);
        }
    }

    /**
     * Opens the info window with the specified content.
     * Sets the position of the info window to match the clicked marker's position.
     * @param point The point containing the info to display
     */
    openInfoWindow(point: { position: google.maps.LatLngLiteral; label: { text: string } }) {
        // Set the position of the info window and open it
        if (this.infoWindow) {
            this.infoWindow.options = { position: point.position }; // Set info window position
            this.selectedPoint = point;
            this.infoWindow.open();
        } else {
            console.warn('InfoWindow is not available');
        }
    }

    removePointFromMap() {
        const index = this.points.findIndex((p: { position: { lat: number } }) => p.position.lat === this.selectedPoint.position.lat);
        if (index) {
            this.points.splice(index, 1);
            this.removeMarkerOnClick.emit(this.selectedPoint.label.text);
            this.infoWindow.close();
            this.selectedPoint = undefined;
        }
    }

    /**
     * Adjusts the map's bounds to include all markers in the positions array.
     * Uses LatLngBounds to determine the bounding box and fitBounds on the map to set
     * the optimal zoom and center to show all points.
     */
    private fitMapToMarkers(): void {
        const bounds = new google.maps.LatLngBounds();

        // Extend bounds for each position in the array
        this.points.forEach((point) => bounds.extend(point.position));

        // Use fitBounds to adjust the map's view
        if (this.points.length > 1 && this.mapComponent && this.mapComponent.googleMap) {
            this.mapComponent.googleMap.fitBounds(bounds);
        }
    }

    /**
     * Called when the map becomes idle after rendering.
     * Triggers the fitMapToMarkers function only on the first load.
     */
    onMapIdle(): void {
        if (this.isFirstLoad) {
            this.fitMapToMarkers(); // Adjust bounds on first load only
            this.isFirstLoad = false; // Set flag to prevent further automatic centering
        }
    }
}
